import React from 'react';
import style from './Style/style.module.css'

function Page() {
    return (
        <>
            About
        </>
    );
}

export default Page;