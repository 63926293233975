import React from 'react';
import { useState } from 'react';
import { NavLink } from "react-router-dom";
import style from './style/style.module.css'

import Logo from 'Media/Images/Logo.png'
import { BiMobile } from 'react-icons/bi';
import { IoNotificationsCircleOutline } from 'react-icons/io5';

function Component() {
    const [navTop, setNav] = useState(1);
    const [navActive, setNavActive] = useState(0)
    window.addEventListener('scroll', navTopToggle);
    const ToggleThreshold = 75;
    function navTopToggle() {
        if (window.scrollY <= ToggleThreshold) {
            setNav(1);
        }
        if (window.scrollY > ToggleThreshold) {
            setNav(0);
        }
    }

    return (
        <>
            <div className={navTop ? style.NavContainerTop : style.NavContainer}>
                <div className={style.NavContent}>
                    <div className={style.Brand}>
                        <NavLink to='/'>
                            <img src={Logo} alt="" />
                        </NavLink>
                    </div>

                    <div className={style.MobileNav}>
                        <div className={navActive ? style.MobileNavToggleActive : style.MobileNavToggle} onClick={() => { navActive ? setNavActive(0) : setNavActive(1) }}>
                            <div className={style.openBtn}>
                                <div className={style.topLine} />
                                <div className={style.midLine} />
                                <div className={style.bottomLine} />
                            </div>

                            <div className={style.closeBtn}>
                                <div className={style.LeftLine} />
                                <div className={style.RightLine} />
                            </div>
                        </div>
                    </div>

                    <div className={style.NavLinks}>
                        <NavLink className={({ isActive }) => (isActive ? 'activeLink' : null)} onClick={() => setNavActive(0)} to="/">Home</NavLink>
                        <NavLink className={({ isActive }) => (isActive ? 'activeLink' : null)} onClick={() => setNavActive(0)} to="/projects">Projects</NavLink>
                        <NavLink className={({ isActive }) => (isActive ? 'activeLink' : null)} onClick={() => setNavActive(0)} to="/about">About</NavLink>
                        <NavLink className={({ isActive }) => (isActive ? 'activeLink' : null)} onClick={() => setNavActive(0)} to="/contact">Contact</NavLink>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Component;