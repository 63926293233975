import React from 'react';
import style from './Style/style.module.css'

import { Link } from 'react-router-dom';

import TextScrambleComponent from 'Components/TextScramble';

const PageData = require("./Data/data").default

function Page() {
    return (
        <div className={style.PPContainer}>
            <div className={style.TitleContainer}>
                <TextScrambleComponent phrases={["Terms of Use"]} delay={10000} />
            </div>
            <div className={style.PPDesc}>
                <p>These Terms of Use ("Terms") govern your access to and use of the services provided by Deckers Digital ("the Company"), including but not limited to website development, app development, desktop application development, hosting, and cloud services (collectively, "the Services"). Please read these Terms carefully before using the Services.</p>

                <p>By accessing or using the Services, you agree to be bound by these Terms. If you do not agree to all of these Terms, you may not access or use the Services.</p>

                <p>Created on: {PageData.edits.created}, Last Updated on: {PageData.edits.lastUpdated}</p>
            </div>

            <div className={style.PPSection}>
                <h2>1. Use of the Services</h2>

                <p>1.2. You agree to use the Services only for lawful purposes and in accordance with these Terms.</p>

                <p>1.3. You agree not to use the Services:</p>
                <ul>
                    <li>In any way that violates any applicable federal, state, local, or international law or regulation.</li>
                    <li>To engage in any conduct that restricts or inhibits anyone's use or enjoyment of the Services, or which may harm the Company or its users.</li>
                </ul>
            </div>

            <div className={style.PPSection}>
                <h2>2. Intellectual Property Rights</h2>

                <p>2.1. The Services and their original content, features, and functionality are owned by the Company and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>

                <p>2.2. You may not modify, reproduce, distribute, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material provided as part of the Services.</p>

            </div>

            <div className={style.PPSection}>
                <h2>3. Disclaimer</h2>

                <p>3.1. The content and materials provided as part of the Services are provided for general information purposes only. They are not intended to provide legal, financial, medical, or any other type of professional advice. You should not rely on this information as a substitute for professional advice.</p>

                <p>3.2. The Company makes no representations or warranties of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information provided as part of the Services.</p>

            </div>

            <div className={style.PPSection}>
                <h2>4. Limitation of Liability</h2>

                <p>4.1. In no event shall the Company, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Services; (ii) any conduct or content of any third party using the Services.</p>

            </div>

            <div className={style.PPSection}>
                <h2>5. Governing Law</h2>

                <p>5.1. These Terms shall be governed and construed in accordance with the laws of [Your Country], without regard to its conflict of law provisions.</p>

            </div>

            <div className={style.PPSection}>
                <h2>6. Changes to These Terms</h2>

                <p>6.1. The Company reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least [number] days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>



            </div>
            <div className={style.PPSection}>
                <h2>7. Contact Us</h2>
                <p>If you have any questions about these Terms, please contact us at vincent@deckersdigital.be. or by going to our <Link to="/contact" style={{ textDecoration: "underline" }}>Contact Page</Link></p>

            </div>
        </div>
    );
}

export default Page;