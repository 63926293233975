import React from 'react';
import style from './style/style.module.css'
import { Link } from 'react-router-dom';

import { BiLogoFacebook } from "react-icons/bi";
import { BiLogoInstagram } from "react-icons/bi";
import { BiLogoLinkedin } from "react-icons/bi";
import { BiLogoDiscordAlt } from "react-icons/bi";
import { BiLogoTiktok } from "react-icons/bi";
import { BiLogoGithub } from "react-icons/bi";

function Index() {
    return (
        <div className={style.Footer}>
            <div className={style.Links}>
                <Link>
                    <BiLogoFacebook />
                </Link>
                <Link>
                    <BiLogoInstagram />
                </Link>
                <Link>
                    <BiLogoTiktok />
                </Link>
                <Link>
                    <BiLogoDiscordAlt />
                </Link>
                <Link>
                    <BiLogoGithub />
                </Link>
                <Link>
                    <BiLogoLinkedin />
                </Link>
            </div>
            <div className={style.Bottom}><span>&#169; 2024 Deckers Digital</span> - <Link to="/termsofuse">Terms of use</Link> - <Link to="/privacypolicy">Privacy Policy</Link></div>
        </div>
    );
}

export default Index;