const projects = [
    {
        name: "SkillSwap",
        backgroundImg: require('Media/Images/Projects/Backgrounds/SkillSwap.png'),
        logo: require("Media/Images/Logo.png"),
        catagorie: "app"
    },
    {
        name: "Foodstep Uganda",
        backgroundImg: require('Media/Images/Projects/Backgrounds/FUWebsite.png'),
        logo: require("Media/Images/Logo.png"),
        catagorie: "web",
        simulatorURL:"https://foodstep.deckersdigital.be/"
    },
    {
        name: "Mystery Seeds",
        backgroundImg: require('Media/Images/Projects/Backgrounds/MSWebsite.png'),
        logo: require("Media/Images/Projects/Logos/mysteryseedslogo.png"),
        catagorie: "web",
        simulatorURL:"https://mysteryseeds.be/"
    }
]

export default { projects }
