import React from 'react';
import style from './Style/style.module.css'
import { useParams } from 'react-router';

function Page() {
    const params= useParams()
    return (
        <>
            {params.project}
        </>
    );
}

export default Page;