import React, { useState } from 'react';
import style from './Style/style.module.css'
import { IoInformationCircleOutline } from "react-icons/io5";
import { MdOutlineContactSupport } from "react-icons/md";
import { BsChatRightText } from "react-icons/bs";
import { MdMailOutline } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";

import { FiPhone } from "react-icons/fi";

const ContactForm = ({ formControl }) => {
    return (
        <div className={style.ContactContent}>
            <IoIosCloseCircleOutline onClick={() => { formControl(0) }} className={style.closeFormBtn}/>
            <h1>Get in touch</h1>
            <form>
                <input type="text" placeholder='Name' name="nameInp" id="" />
                <input type="text" placeholder='Email' name="emailInp" id="" />
                <textarea type="text" placeholder='Message' name="MessageInp" id="" />
                <button type='submit'>Submit</button>
            </form>
        </div>
    )
}

const InfoForm = ({ formControl }) => {
    return (
        <div className={style.ContactContent}>
            <IoIosCloseCircleOutline onClick={() => { formControl(0) }} className={style.closeFormBtn} />
            <div className={style.infoContainer}>
                <div className={style.infoContent}>
                    <FiPhone/>
                    <h1>Phone</h1>
                    <span>+32 473 24 35 20</span>
                </div>
                <div className={style.HDivider}/>
                <div className={style.infoContent}>
                    <MdMailOutline/>
                    <h1>Mail</h1>
                    <span>dedecker.vince@gmail.com</span>
                </div>
            </div>
        </div>
    )
}

const ChatForm = ({ formControl }) => {
    return (
        <div className={style.ContactContent}>
            <IoIosCloseCircleOutline onClick={() => { formControl(0) }} className={style.closeFormBtn} />
            <h1>No live chat <br/>availble  .  .  . Yet</h1>
        </div>
    )
}

function Page() {
    const [contactPage, setContactPage] = useState();
    return (
        <>
            <div className={style.ContactSection}>
                <div className={style.ContactContainer}>
                    <div className={style.ContactBackgroundOverlay} />
                    <div className={contactPage ? style.ContactBtnContainerClosed : style.ContactBtnContainer}>
                        <button className={style.formBtn} onClick={() => { setContactPage("info") }}><IoInformationCircleOutline /></button>
                        <button className={style.formBtn} onClick={() => { setContactPage("contact") }}><MdMailOutline /></button>
                        <button className={style.formBtn} onClick={() => { setContactPage("chat") }}><BsChatRightText /></button>
                    </div>
                    {contactPage === "contact" ? <ContactForm formControl={setContactPage}/> : null}
                    {contactPage === "info" ? <InfoForm formControl={setContactPage}/> : null}
                    {contactPage === "chat" ? <ChatForm formControl={setContactPage}/> : null}
                </div>
            </div>
        </>
    );
}

export default Page;