import {Routes, Route } from "react-router-dom";
import Cursor from "Components/Cursor";
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

import Home from './Pages/Home'
import Projects from './Pages/Projects'
import Contact from './Pages/Contact'
import About from './Pages/About'
import ProjectShowcase from './Pages/ProjectShowcase'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import TermsOfUse from './Pages/TermsOfUse'
import NoPage from './Pages/NoPage'

function App() {
  return (
    <>
      <div className='appBackground'/>
      <Cursor/>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/projects" element={<Projects/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/project/:project" element={<ProjectShowcase/>}/>
        <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
        <Route path="/termsofuse" element={<TermsOfUse/>}/>
        <Route path="*" element={<NoPage/>}/>
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
