import React from 'react';
import style from './style/style.module.css'

import UseOnScreen from 'Hooks/UseOnScreen';

import { ImSphere } from "react-icons/im";
import { FaComputer } from "react-icons/fa6";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { CiCloudOn } from "react-icons/ci";

import * as Icons from "react-icons"

import { Link } from 'react-router-dom';

function index({ projects }) {
    const [projectsRef, isProjectsVisible] = UseOnScreen();
    return (
        <>
            <div className={isProjectsVisible ? style.ProjectShowcaseAni : style.ProjectShowcase} ref={projectsRef}>
                {projects && projects.map((project) => {
                    return (
                        <Link to={"/project/"+project.name} key={project.name} className={style.ProjectContainer}>
                            <div className={style.ProjectBackground} style={{backgroundImage: `url(${project.backgroundImg})`}}/>
                            <div className={style.ProjectOverlay}/>
                            <div className={style.ProjectContent}>
                                <div className={style.ProjectIcon}>
                                    {project.catagorie === "web" ? <ImSphere /> : null}
                                    {project.catagorie === "app" ? <IoPhonePortraitOutline /> : null}
                                    {project.catagorie === "desktop" ? <FaComputer /> : null}
                                    {project.catagorie === "hosting" ? <CiCloudOn /> : null}
                                </div>
                                <div className={style.ProjectUser}>
                                    <img className={style.UserLogo} src={project.logo} alt="" />
                                    <span className={style.ProjectName}>{project.name}</span>
                                </div>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </>
    );
}

export default index;