import React from 'react';
import style from './Style/style.module.css'

import UseOnScreen from 'Hooks/UseOnScreen';

import TextScramble from 'Components/TextScramble';
import ProjectShowcase from 'Components/ProjectShowcase'

import { ImSphere } from "react-icons/im";
import { FaComputer } from "react-icons/fa6";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { CiCloudOn } from "react-icons/ci";

import { Link } from 'react-router-dom';

const PageData = require("./data/data").default

function Page() {
    const [catagorieRef, isCatagoryVisible] = UseOnScreen();

    return (
        <>
            <div className={style.HeroSection}>
                <div className={style.HeroTitle}>
                    <TextScramble phrases={PageData.phrases}/>
                </div>
                <div className={style.HeroActionCall}>
                    <button>Start Creating</button>
                </div>
            </div>

            <div className={style.CatagorieSection}>
                <div className={style.TitleSection}><h1>What We Do</h1></div>
                <div className={isCatagoryVisible ? style.CatagoriesMainContainerAni : style.CatagoriesMainContainer} ref={catagorieRef}>
                    <Link to="/web">
                        <div className={style.CatagorieContainer} >
                            <ImSphere className={style.icon} />
                            <span className={style.title}>Website Developmnt</span>
                        </div>
                    </Link>
                    <Link to="/applications">
                        <div className={style.CatagorieContainer}>
                            <FaComputer className={style.icon} />
                            <span className={style.title}>Application Developmnt</span>
                        </div>
                    </Link>
                    <Link to="/apps">
                        <div className={style.CatagorieContainer}>
                            <IoPhonePortraitOutline className={style.icon} />
                            <span className={style.title}>Mobile App Development</span>
                        </div>
                    </Link>
                    <Link to="cloud">
                        <div className={style.CatagorieContainer}>
                            <CiCloudOn className={style.icon} />
                            <span className={style.title}>Cloud Infrastructure & Hosting</span>
                        </div>
                    </Link>
                </div>
            </div >
            <div className={style.ProjectsSection}>
                <div className={style.ProjectsContainer}>
                    <div className={style.TitleSection}>
                        <h1>Our newest Projects</h1>
                    </div>
                    <div className={style.ProjectContent}>
                        <ProjectShowcase projects={PageData.projects}/>
                    </div>
                </div>
            </div>
            {/* <div className={style.PartnersSection}>
                <div className={style.TitleSection}><h1>Some Of Our Partners</h1></div>
                <div className={style.PartnersContainer}>
                    <span>Become our first parter today!</span>
                </div>
            </div> */}
        </>
    );
}

export default Page;