import React from 'react';
import { useNavigate } from 'react-router-dom';

function NoPage() {
    const navigate = useNavigate();
    return (
        <div style={{width:"100vw", height:"calc(100vh - calc(var(--footer-height) + var(--footer-topMargin) - 1px))", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", gap:"25px"}}>
            <h1 style={{color:"white", fontFamily:"progress", fontSize:"5em", textAlign:"center", marginTop:"100px"}}>404 No Page</h1>
            <span style={{color:"white", fontFamily:"progress", fontSize:"2em", textAlign:"center"}}>If you think this is a mistake, please contact us</span>
            <button onClick={() => navigate(-1)} style={{width:"150px", height:"50px" ,backgroundColor:"transparent", border:"3px white solid", borderRadius:"50px", color:"white", fontSize:"28px", cursor:"pointer", zIndex:9999}}>Go Back</button>
        </div>
    );
}

export default NoPage;