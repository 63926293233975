import React, { useEffect, useState } from 'react';

const Cursor = () => {
  const [cursorStyle, setCursorStyle] = useState({});
  const [cursorState, setCursorState] = useState("");

  useEffect(() => {
    const handleMouseMove = (e) => {
      const isPointerElement = window.getComputedStyle(e.target).cursor === 'pointer';
      setCursorStyle({
        top: `${e.clientY - 10}px`,
        left: `${e.clientX - 10}px`,
      });
      if(cursorState!=="clicked"){
        setCursorState(isPointerElement ? "pointer" : "");
      }
    };

    const handleMouseClick = () => {
      if (cursorState === "pointer") {
        setCursorState("clicked");
        setTimeout(() => {
          setCursorState("");
        }, 500);
      }
    };

    if (!('ontouchstart' in window)) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('click', handleMouseClick);
    }


    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('click', handleMouseClick);
    };
  }, [cursorState]);

  return (
    <div
      className={`cursor ${cursorState}`}
      style={cursorStyle}
    />
  );
};

export default Cursor;