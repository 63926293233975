import React, { useEffect, useState } from 'react';
import style from './Style/style.module.css';

import TextScrambleComponent from 'Components/TextScramble';
import ProjectShowcase from 'Components/ProjectShowcase';

import { ImSphere } from "react-icons/im";
import { FaComputer } from "react-icons/fa6";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { CiCloudOn } from "react-icons/ci";

const PageData = require('./Data/data').default

function Page() {
    const [simulateActive, setSimulateActive] = useState(0);
    const [simulatorURL, setSimulatorURL] = useState("");

    const LoadSimulator = (url) => {
        setTimeout(() => {
            setSimulatorURL(url);
        }, 1000);
    };

    return (
        <>
            <div className={style.MainSection}>
                <div className={style.TitleContainer}>
                    <TextScrambleComponent phrases={["Projects"]} delay={10000} />
                </div>
                <div className={style.ProjectsContainer}>
                    {PageData.projects && PageData.projects.map((project) => {
                        return (
                            <div
                                onClick={() => {
                                    if (project.simulatorURL) {
                                        LoadSimulator(project.simulatorURL);
                                        setSimulateActive(1);
                                    }
                                }}
                                key={project.name}
                                className={style.ProjectContainer}
                            >
                                <div className={style.ProjectBackground} style={{ backgroundImage: `url(${project.backgroundImg})` }} />
                                <div className={style.ProjectOverlay} />
                                <div className={style.ProjectContent}>
                                    <div className={style.ProjectIcon}>
                                        {project.catagorie === "web" && <ImSphere />}
                                        {project.catagorie === "app" && <IoPhonePortraitOutline />}
                                        {project.catagorie === "desktop" && <FaComputer />}
                                        {project.catagorie === "hosting" && <CiCloudOn />}
                                    </div>
                                    <div className={style.ProjectUser}>
                                        <img className={style.UserLogo} src={project.logo} alt="" />
                                        <span className={style.ProjectName}>{project.name}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className={simulateActive ? style.SimulateProjectsSectionActive : style.SimulateProjectsSection}>
                <div className={style.ProjectSimulatorContainer}>
                    {simulatorURL && (
                        <embed src={simulatorURL} className={style.ProjectSimulator} />
                    )}
                    <div className={style.SimulatorTopMenu}>
                        <div className={style.SimulatorTopMenuContent}>
                            <button onClick={() => { setSimulatorURL(""); setSimulateActive(0); }}>Close Simulator</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Page;