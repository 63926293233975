const projects = [
    {
        name: "SkillSwap",
        backgroundImg: require('Media/Images/Projects/Backgrounds/SkillSwap.png'),
        logo: require("Media/Images/Logo.png"),
        catagorie: "app"
    },
    {
        name: "Foodstep Uganda",
        backgroundImg: require('Media/Images/Projects/Backgrounds/FUWebsite.png'),
        logo: require("Media/Images/Logo.png"),
        catagorie: "web",
    },
]

const phrases = [
    'From Creativity To Reality',
    'Deckers Digital',
    "Let's Get Started!",
];

export default { projects, phrases }
